import React from "react";
import ReactDOM from "react-dom/client";
import { AuthContexProvider } from "./context/authContext";
import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <AuthContexProvider>
        <App />
      </AuthContexProvider>
    </React.StrictMode>
  );
} else {
  console.error("Element with ID 'root' not found in the DOM.");
}
