import * as React from 'react';

interface FooterProps{
}

const Footer = (p: FooterProps) => {
return (
<footer>
    <img src="../images/logo.png" alt="logo" />
    <span>
        Made with &#x2764;&#xfe0f; and <b>React.js</b>
    </span>
</footer>
);
}
export default Footer;